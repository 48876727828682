
import react from 'react';
import axios from 'axios'

export async function tryifhere(){
    alert("ani po");
}

export async function VerifyUser(username,password){


    //alert(username + '  and password=' + password);

    return new Promise((resolve,reject)=>{
        var bodyFormData = new FormData()
        bodyFormData.append("username", JSON.stringify({"username":username,"password":password}))
        axios({
            method: 'post',
            url: "https://bengurion.wdev.co.il/api/admin/management/login",
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {

            if(response.data.status=="ok")
            {
                localStorage.setItem("token",response.data['token']);
                //alert("עובדדדד");
                console.log(response.data);
                resolve(true);
            }

        }).catch((error) => {
            alert("ההתחברות כשלה");
            resolve(false);

    })



      });






}

///With token
export async function Verify(){
    let data;
    if(localStorage.getItem("token")==null)
    {
        return {status:false};
    }
    else{
/*
        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({ title: 'React POST Request Example' })
        };
        fetch('https://bengurion.wdev.co.il/api/admin/management/verify', requestOptions)
            .then(response => console.log(response.data))

        } */


         return new Promise((resolve,reject)=>{
            var bodyFormData = new FormData()
            bodyFormData.append("token", JSON.stringify({"token":localStorage.getItem("token")}))
            axios({
                method: 'post',
                url: "https://bengurion.wdev.co.il/api/admin/management/verify",
                data: bodyFormData,
                headers: {'Accept': 'application/json',"Auth": localStorage.getItem("token")}
            })
            .then(function (response) {
                //console.log(response.data)
                if(response.data.status==true){
                    resolve({status:true,obj:response.data});

                }
                else{
                    resolve(false);

                }




             // console.log(response.data.status);

            }).catch((error) => {
                alert("שגיאה");
                resolve(false);

        })



          });




    }
}





 /*    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Auto': 'Bearer ' + cookies.get('token'),
            'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ t: '' })
    };

    try {
        const response = await fetch(url, requestOptions);

        data = await response.json();

        if(response.status==='bad')
        {

            return null;
        }


        if(response.status==402)
        {
            const obj={status:'ok',obj:data.obj};
            return obj;

        }


      } catch (error) {
            console.log(error);
            return null;
      }  */









export default function Req()
{










return(
    <>
    </>
);



}