import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected'
import Sidebar from '../Component/sidebar';
import DashboardStatistic from '../Component/Helpers/dashboardStatistic'
import Forms from '../Component/Helpers/forms';
import CouponList from '../Component/Helpers/CouponList'
import {Sendreq} from '../req';


export default function (props){
  
    const [PresentOFF,SetPresentOFF]=useState(0);
    const [Code,SetCode]=useState("");
    const [CouponLists,SetCouponLists]=useState([]);


    function isANumber(str){
      return !/\D/.test(str);
    }
    

    useEffect(async()=>{
        const Coupon=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/CouponList",{});
        SetCouponLists(Coupon)
        ///console.log(Coupon)

    },[CouponLists])


    async function RemoveCol(val){
      const Coupon=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/RemoveCuopon",{index:val});

      SetCouponLists([]);
       // alert(val);

    }

    function SetPresentOFF_FN(val){
        SetPresentOFF(val);


    }

    function SetCode_FN(val){
      
        SetCode(val);

    }
    async function  insertCoupon(){

      if(Code ==='' || PresentOFF ===''){
        alert("אחד משדות החובה לא מולאו!");

      }
      else if(!isANumber(PresentOFF)){
        alert("אחוז הנחה יכול להכיל מספר בלבד!");
      }

      else{
        var TotalObject={CouponCode:Code,PresentOFF:parseInt(PresentOFF)}

       
        
       
        const AddCoupon=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/AddCoupon",TotalObject);

        if(AddCoupon===true){
           alert("הקופון התווסף בהצלחה");

        }
        else{
            alert("הוספת הקופון נכשלה")
        }

      }

     

       


        
    }


 



    
  

   /// const imag="https://image.tmdb.org/t/p/w500"+movie['poster_path'];

    return (
      <>


<Container fluid>


<Row>
                    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>
                    <Col  xs={10} id="page-content-wrapper">
                   
                        
                          <div  className="contect">
                          <div className="mainatractContect" >
                            <Container >
                               


                              <h2>הוספת קוד קופון</h2>
                              <Row >
                              <Col md={4}><Forms title={"אחוזי הנחה"} clas={""} style={{ width: '280px' }} value={PresentOFF} SetValue={SetPresentOFF_FN} /> </Col>     
                              <Col md={4}><Forms title={"קוד הקופון"} clas={""} style={{ width: '280px' }} value={Code} SetValue={SetCode_FN} /> </Col>     
                              <Col md={4}><Button onClick={insertCoupon} className="SaveAtractbtnCoupon d-flex justify-content-center" variant="success">הוספת קופון</Button>{' '}</Col>

                              </Row>

                              <Row>
                                  <div className="CouponList">
                                    <h2>רשימת קופונים</h2>
                                    </div>

                              </Row>

                              {CouponLists.map((item,index)=>

                                <CouponList key={index} RemoveCol={RemoveCol} {...item}/>


                              
                              )}
                          
                              </Container>
                        
                          </div>
                          </div>
                    </Col> 
                
                </Row>


   
          


</Container>

     </>
    );

}