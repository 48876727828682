import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import TimeInput from './timeinput';

export default function(props){


    const {SetTime,num,day}=props;

    function NewSetStartTime(val){

    const newobj={"Type":"StartTime","index":num,"StartTime":val}



        SetTime(newobj);
        


        console.log(newobj);

    }


    function NewSetFinishTime(val){

        const newobj={"Type":"EndTime","index":num,"EndTime":val}







        SetTime(newobj);
        console.log(newobj);

    }


    function CompareTimes(){
        
    }





    return (
        <>
           <Row>
                                        <Col md={4}>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="יום פעילות"
                                                
                                            >
                                                <Form.Control className="atractinputOpenTime" value={day['HEDayName']} type="text" placeholder="name@example.com" />
                                             </FloatingLabel>
                                        
                                        </Col>
                                       
                                        <Col md={2}>
                                            {num ===0 ?  <h2 className="openHoursh2">שעות הפעילות</h2> : false}
                                        

                                        <TimeInput className="atractinputOpenTime form-control" initTime={day['StartTime'].slice(0, 5)} SetTime={NewSetStartTime} />
                                        </Col>

                                        <Col md={2}>

                                            <TimeInput className="atractinputOpenTime form-control" initTime={day['EndTime'].slice(0, 5)} SetTime={NewSetFinishTime} />
                                            </Col>
                          

                                        </Row>

        </>


     
    );

}