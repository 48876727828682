import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,Table} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected';
import {Sendreq} from '../req'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
import Sidebar from './sidebar';
import EditSubAtract from './EditSubAtract'
registerLocale('he', he)






export default function (props){


    const trypo=props.connectedobj
    const [Isconnected,SetIsconnected]=useState(false);
    const [Islogin,SetIslogin]=useState(false);
    const [Dataobject,SetDataobject]=useState([])
    const [atractType,SetatractType]=useState('');
    const [atractionsList,SetatractionsList]=useState([]);
    const history=useHistory();
    useEffect(async ()=>{

        var url_string = window.location.href; //
        var url = new URL(url_string);
        var atractType = url.searchParams.get("atractType");
        SetatractType(atractType);


       
        const List=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAllsubAtracts",{atractTYPE:atractType});

      
    
        if(List===false )
        {
            alert("שגיאה בטעינת העמוד")
        }
        else{
            SetatractionsList(List)
        }
        //console.log(closingdates)
        console.log(List)

    },[atractionsList])




function editAtract(id){
  history.push({
    pathname:"/EditSubAtract",
                state:{
                  atractType:atractType,
                    atractid:id
                    
                }

  })


}


if(Islogin){
    return (
        <h1>טוען...</h1>
    );
}

function ConvertName(str){
  if(str==='SUBCLOSE'){
    return 'סגירת אטרקציה משנית - קבועה';
  }

}

async function removefromlist(objectid){

 
   var remove=false;
    if (window.confirm("האם אתה בטוח שברצונך למחוק את האטרקציה?")) {
      remove = true;
    } 
    
  

    if(remove){

      const List=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/DelSubAtract",{objremoveid:objectid});
      SetatractionsList([]);
    }

   

   /// alert("אני מוחק את - " +objectid );
  

  

}

function dateformat(date){
  const dateString = date; // ISO8601 compliant dateString
  const D = new Date(dateString);

  return D.getDate()+"/"+ (D.getMonth() + 1)+"/"+D.getFullYear();


}

function ConvertTypeToText(type){
    if(type==='0'){
        return 'אטרקציה קבועה';
    }
    else{
        return 'אטרקציה זמנית';
    }


}


    return (
      <>
{console.log(trypo.data)}





<Container fluid>
    <Row>
    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>

                    <Col  xs={10} id="page-content-wrapper">

                    <div  className="contect">
                          <div className="mainatractContect" >

                      <Container>
      

  <Col md={12}>  
<Table striped bordered hover>
  <thead>
    <tr>
      <th>מחיקה</th>
      <th>עריכה</th>
      <th>כותרת אטרקציה</th>
      <th>סוג אטרקציה</th>
      <th>תיאור</th>
      <th>מחיר</th>

    </tr>
  </thead>
  <tbody>
 {atractionsList && atractionsList.map((obj) =>

<tr>
<td style={{color:"red"}}><a className="Pointer" onClick={()=>{removefromlist(obj.id)}}>מחיקה</a></td>
<td><a onClick={()=>{editAtract(obj.id)}}>עריכה</a></td>
<td>{obj.AtractTitle}</td>
<td>{ConvertTypeToText(obj.Temporary)}</td>
<td>{obj.Description}</td>
<td>{obj.Price}</td>


</tr>



)}


  </tbody>
</Table>

</Col>
</Container>
</div>
</div>
</Col>
</Row>



</Container>
     </>
    );

}

