import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';


export default function Connected(props){





    return (


     <>
  <h1>אני מחובר!!</h1>

     </>
    );

}