import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,Table} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected';
import {Sendreq} from '../req'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
import Sidebar from './sidebar';
registerLocale('he', he)






export default function Closedates(props){


    const trypo=props.connectedobj
    const [Isconnected,SetIsconnected]=useState(false);
    const [Islogin,SetIslogin]=useState(true);
    const [Dataobject,SetDataobject]=useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [FinishDate, SetFinishDate] = useState(new Date());
    const [ClosingReason,SetClosingReason]=useState('');


    useEffect(async ()=>{
        SetIslogin(true)
        const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/returnclosingdays",[]);

        SetIslogin(false)
        console.log(closingdates)
        if(closingdates===false )
        {
            alert("שגיאה בטעינת העמוד")
        }
        else{
            SetDataobject(closingdates)
        }
        //console.log(closingdates)
        console.log(Dataobject)

    },Dataobject)




if(Islogin){
    return (
        <h1>טוען...</h1>
    );
}

async function addtolist(){


  
  var diffTime = (FinishDate - startDate);
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  if(diffDays <0){
    alert("תאריך הסיום חייב להיות גדול מתאריך ההתחלה");
    return;
  }

  var today = new Date();
  //console.log(today)
  var diffTime = (startDate - today );
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  if(diffDays <0){
    alert("ת. תחילת אירולץ חייב להיות גדול או שווה להיום");
    return;
  }
  //console.log(diffDays);


  



   ///
   const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/addclosedate",{Date:startDate,FinishDate:FinishDate,Reasone:ClosingReason});
   if(closingdates!==false){
    SetDataobject(Dataobject => [...Dataobject, closingdates]);

   }
    console.log(closingdates)


}

async function removefromlist(objectid){
  const closingdates=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/removefromclosedatelist",{id:objectid});
  if(closingdates===true){
    //alert("true");
    SetDataobject(Dataobject.filter((item, i) => item.id !== objectid));
    //console.log("")

  }

}

function dateformat(date){
  const dateString = date; // ISO8601 compliant dateString
  const D = new Date(dateString);

  return D.getDate()+"/"+ (D.getMonth() + 1)+"/"+D.getFullYear();


}


    return (
      <>
{console.log(trypo.data)}





<Container fluid>
    <Row>
    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>

                    <Col  xs={10} id="page-content-wrapper">

                    <div  className="contect">
                          <div className="mainatractContect" >

                      <Container>
                        <Row style={{marginBottom:"35px"}}>

<Col md={3}><label style={{marginBottom:"10px"}}>מתאריך</label><DatePicker locale="he" className={"form-control"} dateFormat='dd/MM/yyyy' selected={startDate} onChange={(date) => setStartDate(date)} /></Col>
<Col md={3}><label style={{marginBottom:"10px"}}>עד תאריך</label><DatePicker locale="he" className={"form-control"} dateFormat='dd/MM/yyyy' selected={FinishDate} onChange={(date) => SetFinishDate(date)} /></Col>


    <Col md={3}>
    <Form.Group controlId="closing">
      <Form.Label>סיבת סגירה</Form.Label>
      <Form.Control value={ClosingReason} onChange={(e)=>{SetClosingReason(e.target.value)}} type="text" placeholder="סיבת סגירה" />
    </Form.Group>
    </Col>
    <Col md={3}>
    <Button onClick={addtolist} style={{marginTop:"30px",width:"100%"}} variant="success" type="submit">
    הוסף יום לסגירה
  </Button>
  </Col>
</Row>

  <Col md={12}>  
<Table striped bordered hover>
  <thead>
    <tr>
      <th>מחיקה</th>
      <th>ת. תחילת סגירה</th>
      <th>ת. סיום סגירה</th>
      <th>סיבת סגירה</th>

    </tr>
  </thead>
  <tbody>
 {Dataobject && Dataobject.map((obj) =>

<tr>
<td style={{color:"red"}}><a onClick={()=>{removefromlist(obj.id)}}>מחיקה</a></td>
<td>{dateformat(obj.Closing_Date)}</td>
<td>{dateformat(obj.Untill_CloseDate)}</td>
<td>{obj.Closing_Reason}</td>

</tr>



)}


  </tbody>
</Table>

</Col>
</Container>
</div>
</div>
</Col>
</Row>



</Container>
     </>
    );

}

