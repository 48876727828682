import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected'
import Sidebar from '../Component/sidebar';
import DashboardStatistic from '../Component/Helpers/dashboardStatistic';
import {Sendreq} from '../req';



export default function (props){

  const [StartObject,SetStartObject]=useState([]);




  useEffect(async ()=>{

    const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/BasicDeshboard",{});
    SetStartObject(obj);
    //console.log(obj);





  },[])
  
 



    
  

   /// const imag="https://image.tmdb.org/t/p/w500"+movie['poster_path'];

    return (
      <>


<Container fluid>

<Row>
                    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>
                    <Col  xs={10} id="page-content-wrapper">
                        
                          <div className="contect">
                            <Container>
                              <h1>מערכת ניהול בן גוריון</h1>
                              <Row>
                                <Col md={4}><DashboardStatistic Title={"כמות מבקרים החודש"} number={StartObject.VisitorsMonthly} /></Col>
                                <Col md={4}><DashboardStatistic Title={"מספר הזמנות החודש"} number={StartObject.OrdersNumber} /></Col>
                                <Col md={4}><DashboardStatistic Title={"מספר הזמנות היום"} number={StartObject.TodayOrders} /></Col>
                              </Row>
                              </Container>
                          </div>
                         
                    </Col> 
                </Row>


   



</Container>
     </>
    );

}