import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';



export default function(props){

    const {title,value,SetValue,style,clas}=props;
    





    return (
        <>
                    <FloatingLabel
                    controlId="floatingInput"
                     label={title}
                    className={clas}
                    

                  >
                 <Form.Control value={value}  style={style} onChange={(e)=>{SetValue(e.target.value)}} type="text" placeholder="name@example.com" />
                  </FloatingLabel>


        </>


     
    );

}