import React from 'react';
import CountUp from 'react-countup';
import { useState,useEffect } from 'react';

export default function(props){

    const {Title,AvlStock,UseStock}=props;
    const [UsingStock,SetUsingStock]=useState(0);
    const [Present,SetPresent]=useState(0);

    useEffect(()=>{

      

        


       





    },[])
    



    return (
        <div className="StatisticCube2">
            <h4>{Title}</h4>
            <span>{UseStock}/{AvlStock}</span>
            

         {/*    <span><CountUp duration={1.5} end={parseInt(number)} />

            </span> */}

            <p>
                <span>סה"כ אחוזי תפוסה - {((parseInt(UseStock)/parseInt(AvlStock))*100).toFixed(0)}%</span>
            </p>

        </div>


     
    );

}