import {react,useState,useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { tryifhere,Verify } from './verifysystem';
import Login from './Component/Login'
import PrivateRoute from './Component/PrivateRoute'
import Connected from './Component/Connected';
import Closedates from './Component/Closedates';
import CustomersType from './Component/CustomersType';
import Dashboard from './Component/Dashboard';
import Mainattraction from './Component/Mainattraction'
import Coupon from './Component/Coupon'
import AddsubAtract from './Component/Addsubatract'
import Constraints from './Component/Constraints';
import ConstraintsList from './Component/ConstraintsList';
import SubAttracrionList from './Component/SubatractionList';
import Orders from './Component/Orders';
import EditSubAtract from './Component/EditSubAtract';
import Statistic from './Component/Statistics';
import EditStockByDate from './Component/EditStockByDate';
import Welcome from './Component/Welcome'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";



function App() {
  let history=useHistory();
  function Movetologin(){
    history.push('/Login');

  }



  useEffect(()=>{

    Verify();

  },[])
  useEffect(()=>{
    if(window.location.pathname.startsWith('/')) {
      ///window.location.href='http://localhost:3000/dashboard';
     ///console.log("אני בהתחלה");
    }

    ///window.location.href='http://localhost:3000/dashboard';

  },[])
  const [RedirectLogin, SetRedirectLogin] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <img src="bengutionlogo.png"></img>


        {Movetologin}
      </header>
     



<Router>
      <div>
     {/*  <Link  to="/dashboard"><span style={{textAlign:"center"}} className="boldli">כניסה לדשבורד</span></Link> */}
 
        <nav>
          <ul>
        
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
       
        <Route path="/Login">
            <Login />
        </Route>
       <PrivateRoute path="/protected" component={Connected} >

             </PrivateRoute>

             <PrivateRoute path="/EditSubAtract" component={EditSubAtract} >

</PrivateRoute>

             <PrivateRoute path="/closedates" component={Closedates} >

            </PrivateRoute>

            <PrivateRoute path="/customerstype" component={CustomersType} >

            </PrivateRoute>

            <PrivateRoute path="/dashboard" component={Dashboard} >

            </PrivateRoute>
            <PrivateRoute path="/Mainattraction" component={Mainattraction} >

            </PrivateRoute>

            <PrivateRoute path="/Addcoupon" component={Coupon} >

            </PrivateRoute>


            <PrivateRoute path="/Addsubatraction" component={AddsubAtract} >

            </PrivateRoute>

            <PrivateRoute path="/Constraints" component={Constraints} >

            </PrivateRoute>


            <PrivateRoute path="/ConstraintsList" component={ConstraintsList} >

            </PrivateRoute>


            <PrivateRoute path="/SubAttracrionList" component={SubAttracrionList} >

        </PrivateRoute>

        <PrivateRoute path="/Orders" component={Orders}>


        </PrivateRoute>

        
        <PrivateRoute path="/Statistic" component={Statistic}>


        </PrivateRoute>


        <PrivateRoute path="/EditStockByDate" component={EditStockByDate}>

        </PrivateRoute>


        <PrivateRoute path="/Welcome" component={Welcome}>

</PrivateRoute>



        
        



        
            


            
            


        </Switch>
      </div>
    </Router>

    </div>


  );
}

export default App;
