import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import {Sendreq} from '../../req';
import DateRange from '../Helpers/DatePick';
import OpenHours from '../Helpers/DaysHours';
import MainAtractChangeHours from './MainAtractChangeHours'
import TemperoryFixedAtract from './TemperoryFixedAtract';
import TemporaryAttractionActivation from './TemporaryAttractionActivation';
import CloseStockSubAtraact from './CloseStockSubAtraact';
import MainAtractStockClose from './MainAtractStockClose';






export default function(props){

    function SetTime(val){
        var temp=DaysInWeek;
        temp[val['index']][val['Type']]=val[val['Type']];
        
        console.log(temp);
    }



  

    const array=[
        { AtractID: '34', ENLDayName: 'Sunday', HEDayName: 'יום ראשון', StartTime: '09:00:00', EndTime:'18:00:00'},
        { AtractID: '34', ENLDayName: 'Monday', HEDayName: 'יום שני', StartTime: '09:00:00', EndTime:'18:00:00'},
           { AtractID: '34', ENLDayName: 'Tuesday', HEDayName: 'יום שלישי', StartTime: '09:00:00', EndTime:'18:00:00'},
        { AtractID: '34', ENLDayName: 'Wednesday', HEDayName: 'יום רביעי', StartTime: '09:00:00', EndTime:'18:00:00'},
        { AtractID: '34', ENLDayName: 'Thursday', HEDayName: 'יום חמישי', StartTime: '09:00:00', EndTime:'18:00:00'},
       { AtractID: '34', ENLDayName: 'Friday', HEDayName: 'יום שישי', StartTime: '09:00:00', EndTime:'18:00:00'},
       { AtractID: '34', ENLDayName: 'Saturday', HEDayName: 'יום שבת', StartTime: '09:00:00', EndTime:'18:00:00'}
        ]; 


       




    const {type}=props;
    const [SelectedOption,SetSelectedOption]=useState("0")
    const [MainAtractList,SetMainAtractList]=useState([]);
    const [SubAtractList,SetSubAtractList]=useState([]);
    const [StockSubAtract,SetStockSubAtract]=useState([]);
    const [SelectedOprion,SetSelectedOprion]=useState("0");
    const [DaysInWeek,SetDaysInWeek]=useState(array);
    const [SubAtractions,SetSubAtractions]=useState([]);
    const [SubTempAtractions,SetSubTempAtractions]=useState([]);
    const [SubAtractionsClose,SetSubAtractionsClose]=useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [FinishDate, SetFinishDate] = useState(new Date(Date.now() + 1*24*60*60*1000));

    
    useEffect(()=>{
     

    },[type])
  
    function SetDateStart(val){
        setStartDate(val);
    }
    function SetDateFinish(val){
        SetFinishDate(val);
    }


    useEffect(async ()=>{
        if(type==="1" || type==="6"){
            ///alert("אני שולח בקשה")
            const MainList=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractions",{type:"main"});
            SetMainAtractList(MainList);
            //console.log(MainList)


        }
        if(type==="2"){

            const Sub=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractions",{type:"Sup"});
            SetSubAtractions(Sub);



        }
        if(type==="3"){
            const Sub=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractions",{type:"Sup"});
            SetSubAtractionsClose(Sub);



        }
        if(type==="4"){
            const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractions",{type:"SupTemp"});
            SetSubTempAtractions(SubTemp);


        }
        if(type==="5"){


            const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractions",{type:"SubStock"});
           /// SetSubTempAtractions(SubTemp);
            SetStockSubAtract(SubTemp)

        }


    },[])

    function SendToapiTypeOne(){
      
    }
    function SendToapiTypeTwo(){
        
    }

    async function SendToapiTypeThree(){
        const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/Constraints",{type:"SUBCLOSE",StartDate:startDate,FinishDate:FinishDate,AtractID:SelectedOprion});
      
        if(SubTemp===true){
            alert("האילוץ התווסף בהצלחה!");
        }
        if(SubTemp==='NOTALLOWDATE'){
            alert("לתאריכים הנל כבר קיימים אילוץ. לא ניתן להכניס כמה אילוצים על תאריך אחד")
        }

    }


    if(type==="1"){

        return(
            <>

       
            <MainAtractChangeHours MainAtractList={MainAtractList}/>
            </>
        
            );
      

    }

    if(type==="2"){

        return (
            <>

            <TemperoryFixedAtract SubAtractions={SubAtractions} />
              
        
            </>
    
    
        );
    

    }

    if(type==="3"){

        return (
            <>
                 <h1 class="h1mainatract">בחר אטרקציה</h1>
                                <Form.Select onChange={(e)=>{SetSelectedOprion(e.target.value)}} className="SelectSize">
                                <option value="0">יש לבחור אחת מהאופציות</option>
                                {SubAtractionsClose.map((item,index)=>
                                <option value={item.id}>{item.AtractTitle}</option>
                                
                                
                                )}
                                   
                                </Form.Select>

                 {SelectedOprion !=='0' ? <DateRange SetDateFinish={SetDateFinish} SetDateStart={SetDateStart} /> : false}  
                { SelectedOprion !=='0' ? <Button  onClick={SendToapiTypeThree} className="SaveAtractbtn d-flex justify-content-center" variant="success">הוספת אילוץ</Button> : false}

    
        
            </>
    
    
         
        );


    }

    if(type==="4"){
        return (
            <>
              <TemporaryAttractionActivation SubTempAtractions={SubTempAtractions} />
    
        
            </>
    
    
         
        );
    }

    if(type==="5"){

        return(
            <>
            <CloseStockSubAtraact StockSubAtract={StockSubAtract} />
            </>
        );
    }

    if(type==="6")
    {

        return(
            <>
                <MainAtractStockClose  MainAtractList={MainAtractList} />
            </>
        );

    }



}