import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import Sidebar from './sidebar'
import Forms from './Helpers/forms'
import {Sendreq,SendFile} from '../req';
import SwitchToggle from "react-switch";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import SubAtractStatistic from './Helpers/SubAtractStatistic';



export default function(props){

    const [WelcomeTitle,SetWelcomeTitle]=useState("");
    const [Description , SetDescription]=useState("")
   


    useEffect(async ()=>{

        const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/Management/Welcome",{});
        SetWelcomeTitle(obj[0]['Title'])
        SetDescription(obj[0]['Contect'])
       // console.log(obj)


    },[])
  
    async function SendToApi(){
        const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/Management/WelcomeUpdate",{"Title":WelcomeTitle,"Contect":Description});
        if(obj !==false)
        {
            alert("הפרטים עודכנו בהצלחה");
        }



    }

 
    return (
        <>
    <Container fluid>
  
  <Row>
                      <Col xs={2} id="sidebar-wrapper">      
                        <Sidebar />
                      </Col>
                      <Col  xs={10} id="page-content-wrapper">
                          
                      <div className="mainatractContect">
                            <Container>
                                <div class="contect">
                                <h1>תוכן עמוד ראשי - ברוכים הבאים</h1>

                                <h1 class="h1mainatract WelcomePageTitle">כותרת - ברוך הבא</h1>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="כותרת ברוך הבא"
                                    className="CenterContect atractinput1"
                                >
                                    <Form.Control value={WelcomeTitle} onChange={(e)=>{SetWelcomeTitle(e.target.value)}} type="text"  />
                                </FloatingLabel>


                                
                                <h1 class="h1mainatract WelcomePageTitle">תוכן - ברוך הבא</h1>

                                
                             
                                    <Form.Control
                                    onChange={(e)=>{SetDescription(e.target.value)}}
                                    value={Description}
                                    as="textarea"
                                    placeholder="תוכן ברוך הבא"
                                    className=" atractinput1 CenterContect"
                                    style={{ height: '200px' }}
                                
                                    />
                                   




                            </div>

                            <Button onClick={SendToApi}  className="SaveAtractbtn d-flex justify-content-center" variant="success">שמור פרטים</Button>{' '}

                        
                           
                              </Container>
                          </div>

                      </Col> 
                  </Row>
  
  
     
  
  
  
  </Container>
  
 
       </>
      );

}