import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,InputGroup,SplitButton,Dropdown,FormControl,Table,Pagination} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import {Connected} from '../Component/Connected'
import Sidebar from '../Component/sidebar';
import DashboardStatistic from '../Component/Helpers/dashboardStatistic'
import TableData from './Helpers/TableData';
import {Sendreq} from '../req';

export default function (props){

    const [SearchAction,SetSearchAction]=useState("מספר הזמנה");
    const [active,Setactive]=useState(1);
    const [itemPerPage,SetitemPerPage]=useState(8);
    const [OrderObject,SetOrderObject]=useState([]);
    const [BeforeSearchObject,SetBeforeSearchObject]=useState([]);
    const [PageNumber,SetPageNumber]=useState(1);
    const [SearchForm,SetSearchForm]=useState();

 

    
    async function GetOrderObject(){
  
      const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetOrders",{"NumOfItems":itemPerPage,"ActivePage":active});
      if(obj !==false)
      {

        SetOrderObject(obj);
        SetBeforeSearchObject(obj);
      }
      
      console.log(obj);
      console.log(JSON.parse(obj[0].Order_Details));




    }

    function prev(){
      if(active>1){
        Setactive(active-1);

      }


    }

    function next(){
      if(active<PageNumber){
        Setactive(active+1);

      }
    }

    async function GetNumberOfPages(){
      const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetPagesNum",{"NumOfItems":itemPerPage});
     /// console.log(obj)
      if(obj !==false)
      {

        SetPageNumber(obj);
      }

    }
    useEffect(async ()=>{
      await GetOrderObject();


    },[active])

    useEffect(async ()=>{

     
      await GetNumberOfPages();

    },[])



  
 
    function pagenumber(number){
      Setactive(number);
    }

    async function SerchReq(val){
      console.log(val);
      SetSearchForm(val);
      if(val !=='')
      {
        const obj=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/SearchOrders",{"value":val});
        SetOrderObject(obj);
        SetPageNumber(1)
      }
      else{
        SetOrderObject(BeforeSearchObject)
        GetNumberOfPages();


      }
     
      //console.log(obj)
     
      
      

    }

    //let active = 2;
    let items = [];
    for (let number = 1; number <= PageNumber; number++) {
      items.push(
        <Pagination.Item key={number} onClick={()=>{pagenumber(number)}} active={number === active}>
          {number}
        </Pagination.Item>,
      );
    }

    
  


    return (
      <>


<Container fluid>

<Row>
                    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>
                    <Col  xs={10} id="page-content-wrapper">
                        
                          <div className="contect">
                            <Container>
                              <h1>הזמנות</h1>
                              <Row>
                               <div className="TopGrey">
                               <InputGroup className="OrderSearch">
                                    <FormControl 
                                    placeholder="חיפוש"
                                    onChange={(e)=>{SerchReq(e.target.value)}}
                                    value={SearchForm}
                                     />
                         
                                </InputGroup>

                               </div>
                              </Row>

  {/*  <div className="SelectSort">  
   <h2>הצג</h2>                         
  <Form.Select aria-label="Default select example">

  <option value="1">הכל</option>
  <option value="2">תשלום הושלם</option>
  <option value="3">תשלום כשל / לא הושלם</option>
</Form.Select>
</div> */}
  <Table className="OrderTable" striped bordered hover>
  <thead>
    <tr>
      <th>מימוש</th>
      <th>מספר הזמנה</th>
      <th>תאריך הגעה</th>
      <th>שעת הגעה</th>
    
      <th>שם המזמין</th>
      <th>נייד המזמין</th>
      <th>הערות</th>
      <th>שולם</th>
      <th>פירוט הזמנה</th>
      <th>סטטוס תשלום</th>
    </tr>
  </thead>
  {OrderObject.length===0 ? <h2 className="NoResults">אין תוצאות חיפוש להציג</h2> : false}
  <tbody>
   
    {OrderObject.map((item,index)=>
    <TableData {...item} key={item.id} />
    
    )}
   
  </tbody>
</Table>
<div>
  
<Pagination size="sm">
  
<Pagination.Prev onClick={prev} />
  {items}



<Pagination.Next onClick={next} />



</Pagination>
  </div>
                              </Container>
                          </div>
                         
                    </Col> 
                </Row>


   



</Container>
     </>
    );

}