import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,Table} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)






export default function(props){


    const trypo=props.connectedobj

    const [Isconnected,SetIsconnected]=useState(false);
    const [Islogin,SetIslogin]=useState(true);
    const [Dataobject,SetDataobject]=useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [FinishDate, SetFinishDate] = useState(new Date(Date.now() + 1*24*60*60*1000));
    ///SetFinishDate(FinishDate.getDate() + 1);
    const [ClosingReason,SetClosingReason]=useState('');




    
    function datediff(first, second) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(2008, 1, 12);
        const secondDate = new Date(2008, 1, 22);
        
        return  Math.round((second - first) / oneDay);
    }


function SetStartDateCheck(Startdate){    
    

  /*   
    if(datediff(Startdate,FinishDate)<=0){
        alert("תאריך תחילה לא יכול להיות גדול מתאריך סיום!");
        return;
    } */
    if(datediff(new Date(),Startdate)<0){
        alert("חובה לבחור תאריך תחילה גדול מהיום!");
        return;
    
    }
    setStartDate(Startdate);
    props.SetDateStart(Startdate);
}

function SetFinishDateCheck(Finishdate){    
    

    /*   
      if(datediff(Startdate,FinishDate)<=0){
          alert("תאריך תחילה לא יכול להיות גדול מתאריך סיום!");
          return;
      } */




      if(datediff(new Date(),Finishdate)<0){
          alert("תאריך הסיום חייב להיות שווה להיום לכל פחות");
          return;
      
      }



      SetFinishDate(Finishdate);
      props.SetDateFinish(Finishdate);
 
  }



function dateformat(date){
  const dateString = date; // ISO8601 compliant dateString
  const D = new Date(dateString);

  return D.getDate()+"/"+ (D.getMonth() + 1)+"/"+D.getFullYear();


}


    return (
      <>









       
                        <Row style={{marginBottom:"35px",marginTop:"35px"}}>

<Col md={3}><label style={{marginBottom:"10px" , fontWeight:"bold"}}>מתאריך</label><DatePicker locale="he" className={"form-control"} dateFormat='dd/MM/yyyy' selected={startDate} onChange={(date) => SetStartDateCheck(date)} /></Col>
<Col md={3}><label style={{marginBottom:"10px" , fontWeight:"bold"}}>עד תאריך</label><DatePicker locale="he" className={"form-control"} dateFormat='dd/MM/yyyy' selected={FinishDate} onChange={(date) => SetFinishDateCheck(date)} /></Col>


   
</Row>




     </>
    );

}

