import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';


export default function(props){





    return (


     <div className="sidebar">

<Router>
    
        <nav>
          <ul>
          <li>
              <a href="/dashboard"><span className="boldli">דף בית</span></a>

              </li>

              <li>
            <a href="/Welcome"><span className="boldli"> עריכת עמוד בית - הודעת ברוך הבא</span></a>
            </li>


            <li>
     
              <a href="/Mainattraction?atractType=1"><span className="boldli">אטרקציה ראשית במתחם הצריף</span></a>
              <ul>
                  <li>
                  <a href="/SubAttracrionList?atractType=1">אטרקציות משניות</a>
                  </li>
              </ul>
              <ul>
                  <li>
                  <a href="/Addsubatraction">הוספת אטרקציה משנית</a>
                  </li>
              </ul>


            </li>
            <li>
            <a href="/Mainattraction?atractType=2"><span className="boldli">אטרקציה ראשית במכון בן גוריון</span></a>
           
           
            </li>


            
            <li>
            <a href="/Constraints"><span className="boldli">אילוצים</span></a>
            <ul>
              <li><a href="/ConstraintsList">רשימת אילוצים</a></li>
            </ul>
            </li>
              <li>

            <a href="/Orders"><span className="boldli">הזמנות</span></a>
            </li>
            
            <li>

            <a href="/closedates"><span className="boldli">סגירת צריף</span></a>
            </li>

            <li>
            <a href="/Addcoupon"><span className="boldli">הוסף קופון</span></a>
            </li>

            <li>
            <a href="/Statistic"><span className="boldli">סטטיסטיקות</span></a>
            </li>

         
         

            
          </ul>
        </nav>

        </Router>
        <img id="stickylogo" src="sidebar.png" />


     </div>
    );

}