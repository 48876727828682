import {react,useState,useEffect} from 'react';
import { Button ,Row,Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import {useParams} from 'react-router';



import {Verify} from '../verifysystem';
import {
    Route,
    Redirect

  } from "react-router-dom";

export default function PrivateRoute({component:Component,...rest}){
const [isAuth,setisAuth]=useState(null);
const [ConnectedData,SetConnectedData]=useState({});





 useEffect(async ()=>{
     if( localStorage.getItem('token')!=null)
    {
        await checkconections();

    }
    else
        setisAuth(false);



},[])

async function checkconections(){

    const status =await Verify();
    if(status.status)
    {
        setisAuth(true);
        SetConnectedData(status.obj);
    }

    else{
        setisAuth(false);

    }

}




if(isAuth==null)
{
    return (

        <h1>טוען...</h1>
    );
}




    return(
        <Route
            {...rest}
            render={(props)=>{
            if(isAuth){
                return <Component connectedobj={ConnectedData} />
            }else{
               return (<Redirect to={{pathname:'/login'}} />
               );
            }



        }}/>





    );





}