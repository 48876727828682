import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import {Sendreq} from '../../req';
import DateRange from '../Helpers/DatePick';
import OpenHours from '../Helpers/DaysHours';






export default function(props){

    function SetTime(val){
        var temp=DaysInWeek;
        temp[val['index']][val['Type']]=val[val['Type']];
        
        console.log(temp);
    }



  

    const array=[
        {  ENLDayName: 'Sunday', HEDayName: 'יום ראשון', StartTime: '09:00:00', EndTime:'18:00:00'},
        { ENLDayName: 'Monday', HEDayName: 'יום שני', StartTime: '09:00:00', EndTime:'18:00:00'},
           {  ENLDayName: 'Tuesday', HEDayName: 'יום שלישי', StartTime: '09:00:00', EndTime:'18:00:00'},
        { ENLDayName: 'Wednesday', HEDayName: 'יום רביעי', StartTime: '09:00:00', EndTime:'18:00:00'},
        {  ENLDayName: 'Thursday', HEDayName: 'יום חמישי', StartTime: '09:00:00', EndTime:'18:00:00'},
       {  ENLDayName: 'Friday', HEDayName: 'יום שישי', StartTime: '09:00:00', EndTime:'18:00:00'},
       {  ENLDayName: 'Saturday', HEDayName: 'יום שבת', StartTime: '09:00:00', EndTime:'18:00:00'}
        ]; 


       




    const {type,SubAtractions}=props;
    const [SelectedOption,SetSelectedOption]=useState("0")
    const [MainAtractList,SetMainAtractList]=useState([]);
    const [SubAtractList,SetSubAtractList]=useState([]);
    const [SelectedOprion,SetSelectedOprion]=useState("0");
    const [DaysInWeek,SetDaysInWeek]=useState(array);

    const [startDate, setStartDate] = useState(new Date());
    const [FinishDate, SetFinishDate] = useState(new Date(Date.now() + 1*24*60*60*1000));


  
    function SetDateStart(val){
        setStartDate(val);
    }
    function SetDateFinish(val){
        SetFinishDate(val);
    }


    

    async function SendToapiTypeTwo(){
        const SubTemp=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/Constraints",{type:"STEADYSUBATRACT","StartDate":startDate,"FinishDate":FinishDate,"AtractID":SelectedOprion,"DaysInWeek":DaysInWeek});
        console.log(SubTemp);
        if(SubTemp===true){
            alert("האילוץ התווסף בהצלחה!");
        }
        if(SubTemp==='NOTALLOWDATE'){
            alert("לתאריכים הנל כבר קיימים אילוץ. לא ניתן להכניס כמה אילוצים על תאריך אחד")
    }

}

  


  

   

        return (
            <>
                 <h1 class="h1mainatract">בחר אטרקציה</h1>
                                <Form.Select onChange={(e)=>{SetSelectedOprion(e.target.value)}} className="SelectSize">
                                <option value="0">יש לבחור אחת מהאופציות</option>
                                {SubAtractions.map((item,index)=>
                                <option value={item.id}>{item.AtractTitle}</option>
                                )}
                                   
                                </Form.Select>

                {SelectedOprion !=='0' ? <DateRange SetDateFinish={SetDateFinish} SetDateStart={SetDateStart} /> : false}  
                {SelectedOprion !=='0' && DaysInWeek.map((item,index)=>
                                             <OpenHours key={index} num={index} day={item} SetTime={SetTime} />
                                    
                 )}
                { SelectedOprion !=='0' ? <Button  onClick={SendToapiTypeTwo} className="SaveAtractbtn d-flex justify-content-center" variant="success">הוספת אילוץ</Button> : false}

        
            </>
    
    
        );
    

 


   



}