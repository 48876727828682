import React from 'react';
import CountUp from 'react-countup';

export default function(props){

    const {Title,number}=props;



    return (
        <div className="StatisticCube">
            <h4>{Title}</h4>
            <span><CountUp duration={1.5} end={parseInt(number)} />
            </span>

        </div>


     
    );

}