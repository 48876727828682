import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import { Button,Row,Container,Col,Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';



export default function(props){


        const {typename,price,key,index,RemoveCol}=props;


    return (
        <>
        <Row>
        <Col md={4}><div className="VisitorTypeDIV"><span>{typename}</span></div></Col>
        <Col md={4}><div className="VisitorTypeDIV"><span>₪{price}</span></div></Col>
        <Col md={2}><label onClick={()=>{RemoveCol(typename)}} className="Xbtn">X</label></Col>
        
        </Row>
        </>


     
    );

}