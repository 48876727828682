import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import Sidebar from './sidebar'
import Forms from './Helpers/forms'
import OpenHours from './Helpers/DaysHours';
import {Sendreq,SendFile} from '../req';
import SwitchToggle from "react-switch";
import VisitorsTypes from './Helpers/VisitorsTypes';

export default function(props){

    const location=useLocation();
    const {atractType,atractid}=location.state;
    const [Picture,SetPicture]=useState(null);
    const [MainPhoto,SetMainPhoto]=useState();
    const [SubAtractobj,SetSubAtractobj]=useState([]);
    const [AtractTitle,SetAtractTitle]=useState('');
    const [DaysInWeek,SetDaysInWeek]=useState([]);
    const [Description,SetDescription]=useState('');
    const [btnloading,Setbtnloading]=useState(false);
    const [Temperory,SetTemperory]=useState("0")
    const [StockPerHour,SetStockPerHour]=useState(0)
    const [ToggleCardsType,SetToggleCardsType]=useState(false);
    const [EnterPrice,SetEnterPrice]=useState(0);
    const [VisitorsTypeTEXT,SetVisitorsTypeTEXT]=useState("");
    const [VisitorsPricesTEXT,SetVisitorsPricesTEXT]=useState("");
    const [VisitorsArray,SetVisitorsArray]=useState([]);
    const [Continuousentry,SetContinuousentry]=useState(false);
    const [ImmediateEntry,SetImmediateEntry]=useState(false);
    const [ToggleStock,SetToggleStock]=useState(false);



    function SetVisitorsPricesTEXT_FN(val){
        SetVisitorsPricesTEXT(val);
        
    }

    function SetImmediateEntryFN(val){
        SetImmediateEntry(val);


    }

    

    function SetContinuousentryFN(val){
        SetContinuousentry(val);

    }

    function SetToggleStockFN(val){
        SetToggleStock(val);

    }

    function RemoveCol(name){
    
        SetVisitorsArray(VisitorsArray.filter(item => item.Type_Name !== name));

  
    }
    function SetTime(val){
        var temp=DaysInWeek;

       /// val['index']][val['Type']]=val[val['Type']

        temp[val['index']][val['Type']]=val[val['Type']];
        //SetDaysInWeek()
        console.log(temp)
    }
    function SetValueEnterPrice(val){
        SetEnterPrice(val);
    }
    function SetVisitorsTypeTEXT_FN(val){
        SetVisitorsTypeTEXT(val);



    }
    function btnCliked(){
        if(VisitorsTypeTEXT === '' || VisitorsPricesTEXT === '')
        {
            alert("אחד משדות החובה לא קימות");
        }
        else if(VisitorsPricesTEXT.match(/^[0-9]+$/) == null){
            alert("שדה המחיר חייב להיות מספר!");


        }
        else{
            var exist=VisitorsArray.find((x) => x.VisitorType === VisitorsTypeTEXT);
            if(exist){

                alert("כבר קיים שדה מסוג זה. ראשית יש למחוק אותו .")


            }
            else{
                SetVisitorsArray(VisitorsArray => [...VisitorsArray, {"Type_Name":VisitorsTypeTEXT,"Price":VisitorsPricesTEXT}]);
            }
            
            
      


        }

    }

    function SetMaxNumberPerHourFN(val){
        SetStockPerHour(val);

    }
    function SetToggleCardsTypeFN(val){
        SetToggleCardsType(val);

    }

    async function SendToApi(){
        Setbtnloading(true);
        if(AtractTitle === '' || Description==='')
        {
            alert("אחד מהשדות לא מולאו כנדרש!");

        }

    

        else{
          
            var TotalObject={"id":atractid,"AtractTitle":AtractTitle,"Description":Description,"DaysInWeek":DaysInWeek,"Temperory":Temperory,"MaxNumberPerHour":StockPerHour,"VisitorsArray":VisitorsArray,"MultiVistors":ToggleCardsType,"EnterPrice":EnterPrice,"UsingStock":ToggleStock,"Continuousentry":Continuousentry,"ImmediateEntry":ImmediateEntry}
           console.log("אובייקט לשליחה");
            console.log(DaysInWeek);
         

            if(Picture !== null){
                const uploadimage= await SendFile("https://bengurion.wdev.co.il/api/admin/management/FileUpload",Picture);
                if(uploadimage !==false){

                    const UploadimageURL=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/UpdateLinkSubAtract",{url:uploadimage,id:atractid});
                    console.log(UploadimageURL);

                }

            }
          
            const addAtract=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/EditSubAtract",TotalObject);
            
            if(addAtract===false){
            
             alert("שגיאה.");
         
            }
      

            console.log(addAtract);
            alert("הפרטים עודכנו בהצלחה!");
            Setbtnloading(false);

        }

    }

    const onChangePicture = e => {
        /// alert(e.target.files[0]);
         if (e.target.files[0]) {
           console.log("picture: ", e.target.files);
           SetPicture(e.target.files[0]);
           const reader = new FileReader();
           reader.addEventListener("load", () => {
            SetMainPhoto(reader.result);
           });
           reader.readAsDataURL(e.target.files[0]);
         }
       };

       useEffect(async()=>{

            var objdata=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/EditSubAtractGetData",{id:atractid})
            SetSubAtractobj(objdata)
            SetAtractTitle(objdata['BasicData']['AtractTitle'])
            SetDescription(objdata['BasicData']['Description'])
            SetMainPhoto(objdata['BasicData']['MainPhoto'])
            SetStockPerHour(objdata['BasicData']['MaxNumberPerHour'])
            
            SetContinuousentry(objdata['BasicData']['Continuousentry']==="1" ? true : false);

            SetImmediateEntry(objdata['BasicData']['ImmediateEntry']==="1" ? true : false)

            if(objdata['BasicData']['MaxNumberPerHour']==="-1"){
                SetToggleStock(false);
                SetStockPerHour(0);


            }
            else{
                SetToggleStock(true);

            }


            if(objdata['BasicData']['CardType']==="1"){
                SetToggleCardsType(true);
                SetVisitorsArray(objdata['CustomerType'])



            }
            else{
                SetEnterPrice(objdata['BasicData']['Price']);

                

            }
            ///CardType: "0"



            if(objdata['BasicData']['Temporary']==="0"){
                SetDaysInWeek(objdata['OpenHours'])
                

            }
            else{
                SetTemperory("1")
            }


            console.log(objdata);

       },[])

    return (
        <>
  
  
  <Container fluid>
  
  <Row>
                      <Col xs={2} id="sidebar-wrapper">      
                        <Sidebar />
                      </Col>
                      <Col  xs={10} id="page-content-wrapper">
                          
                      <div className="mainatractContect">
                            <Container>
                              <h2 >עריכת אטרקציה משנית</h2>
                           
                              <h1 className="h1mainatract">פירוט אטרקציה משנית</h1>
                              <FloatingLabel
                                    controlId="floatingInput"
                                    label="כותרת פעילות"
                                    className="mb-3 atractinput1"
                                >
                                    <Form.Control value={AtractTitle} onChange={(e)=>{SetAtractTitle(e.target.value)}} type="text" placeholder="name@example.com" />
                                </FloatingLabel>

                                <FloatingLabel  label="תוכן הפעילות">
                                    <Form.Control
                                    onChange={(e)=>{SetDescription(e.target.value)}}
                                    value={Description}
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    className="atractinput1"
                                    style={{ height: '200px' }}
                                
                                    />
                                    </FloatingLabel>
                                    
                                    <h1 className="h1mainatract">תמונה ראשית</h1>
                                   
                                    <Col md={12}>
                                    <img  className="DelImage" src="delete.png" />
                                        <img className="ShowMainImage" src={MainPhoto} />
                                    
                                    </Col>
                                    <h3 style={{MarginTop:"10px;"}}>העלאת תמונה חדשה</h3>
                                    
                                    <Form.Group controlId="formFileMultiple"  className="mb-3">
                                    <Form.Control onChange={onChangePicture} id="files" className="hidden" type="file" multiple />
                                    <label className="UploadImage" for="files">בחר תמונה ראשית לאטרקציה</label>
                              
                                    
                                    </Form.Group>

                                    <h1 className="h1mainatract">שעות פעילות המתחם</h1>

                                    {DaysInWeek.map((item,index)=>
                                             <OpenHours key={index} num={index} day={item} SetTime={SetTime} />
                                    
                                    )}



                              {/*       {DaysInWeek.map((item,index)=>
                                             <OpenHours key={index} num={index} day={item} SetTime={SetTime} />
                                    
                                    )} */}

<br />                                
<br />
  <span className="Multi">מלאי קיים לאטרקציה : </span><SwitchToggle onChange={SetToggleStockFN} checked={ToggleStock} />

  <br />
                           {ToggleStock && <h1 className="h1mainatract">כמות כרטיסים</h1>}

                            {ToggleStock && <Forms clas={"atractinput1"}  title={"כמות כרטיסים"}  value={StockPerHour} SetValue={SetMaxNumberPerHourFN} />}


                            <br />
                                   
                                   <span className="Multi">מרובה סוגי כרטיסים : </span><SwitchToggle onChange={SetToggleCardsTypeFN} checked={ToggleCardsType} />
                                   <br />
                                   
                                  
                                    {ToggleCardsType ? <h1 className="h1mainatract">הגדרת מבקרים</h1> :<h1 className="h1mainatract">מחיר כרטיס</h1>}
                                   { ToggleCardsType ===false ? <Forms clas={"atractinput1"}  title={"מחיר הכרטיס בשקלים כולל מעמ"}  value={EnterPrice} SetValue={SetValueEnterPrice} /> :<Row><Col md={4}><Forms title={"סוגי מבקרים"} clas={""} style={{ width: '280px' }} value={VisitorsTypeTEXT} SetValue={SetVisitorsTypeTEXT_FN} /></Col><Col md={4}><Forms title={"מחיר לפי מבקר"} clas={""}  style={{ width: '280px' }} value={VisitorsPricesTEXT} SetValue={SetVisitorsPricesTEXT_FN} /></Col><Col md={2}><img onClick={btnCliked} className="addTypeBTN" src="../pluse.png"></img></Col></Row>}

                                   {ToggleCardsType && VisitorsArray.map((item,index)=>
                                     <VisitorsTypes key={index} index={index} typename={item.Type_Name} RemoveCol={RemoveCol} price={item.Price} />
                                    
                                    
                                    )} 
                                   

                                   {/*  <Forms clas={"atractinput1"}  title={"מספר המבקרים המקסימלי בשעה"}  value={100} SetValue={onChangePicture} /> */}

                                   

                         

                             {/*        {VisitorsArray.map((item,index)=>
                                     <VisitorsTypes key={index} index={index} typename={item.Type_Name} RemoveCol={RemoveCol} price={item.Price} />
                                    
                                    
                                    )}  */}
                                            <h1 className="h1mainatract">הגדרות נוספות</h1>

                                                <br />
                                                <br />
                                            <span className="Multi">כניסה רציפה לאטרקציה : </span><SwitchToggle onChange={SetContinuousentryFN} checked={Continuousentry} />

                                            <br />
                                            <br />
                                            <span className="Multi">כניסה לאטרקציה בדיוק בשעת הכניסה : </span><SwitchToggle onChange={SetImmediateEntryFN} checked={ImmediateEntry} />


                          
                                      
                                       
                              

                                      

                           
                              </Container>
                          </div>
                          <Button onClick={SendToApi} disabled={btnloading} className="SaveAtractbtn d-flex justify-content-center" variant="success">{btnloading ? "טוען.." : "שמור"}</Button>{' '}

                      </Col> 
                  </Row>
  
  
     
  
  
  
  </Container>
       </>
      );

}