import React from 'react';
import { useState,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams 
  } from "react-router-dom";
import { Button,Row,Container,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { tryifhere,Verify,VerifyUser } from '../verifysystem';
import Sidebar from '../Component/sidebar';
import TimeInput from './Helpers/timeinput';
import OpenHours from './Helpers/DaysHours';
import Forms from './Helpers/forms';
import VisitorsTypes from './Helpers/VisitorsTypes';
import {Sendreq,SendFile} from '../req';

export default function(props){
     const array={
        0:{"Enl":"Sunday","Heb":"יום ראשון","TimeStart":"09:00","TimeEnd":"17:00"},
        1:{"Enl":"Monday","Heb":"יום שני","TimeStart":"09:00","TimeEnd":"17:00"},
        2:{"Enl":"Tuesday","Heb":"יום שלישי","TimeStart":"09:00","TimeEnd":"17:00"},
        3:{"Enl":"Wednesday","Heb":"יום רביעי","TimeStart":"09:00","TimeEnd":"17:00"},
        4:{"Enl":"Thursday","Heb":"יום חמישי","TimeStart":"09:00","TimeEnd":"17:00"},
        5:{"Enl":"Thursday","Heb":"יום שישי","TimeStart":"09:00","TimeEnd":"17:00"},
        6:{"Enl":"Saturday","Heb":"יום שבת","TimeStart":"09:00","TimeEnd":"17:00"}
    }; 
    const { id } = useParams()
    const [VisitorsTypeArray,SetVisitorsTypeArray]=useState([]);
    const [Visitor,SetVisitor]=useState("");
    const [VisitorPrice,SetVisitorPrice]=useState("");
    const [TitleVal,SetTitleVal]=useState("");
    const [Textura,SetTextura]=useState("");
    const [MaxNumber,SetMaxNumber]=useState(0);
    const [DaysInWeek,SetDaysInWeek]=useState([]);
    const [VisitorsTypeTEXT,SetVisitorsTypeTEXT]=useState("");
    const [VisitorsPricesTEXT,SetVisitorsPricesTEXT]=useState("");
    const [VisitorsArray,SetVisitorsArray]=useState([]);
    const [Attractid,SetAttractid]=useState();
    const [MainPhoto,SetMainPhoto]=useState();
    const [btnloading,Setbtnloading]=useState(false);
    const [atractType,SetatractType]=useState('');
    const [Dbobject,SetDbobject]=useState([]);
    const [Picture,SetPicture]=useState(null);
    String.prototype.isNumber = function(){return /^\d+$/.test(this);}

    function DelImage(){
        SetPicture(null);
        SetMainPhoto(Dbobject.BasicData['MainPhoto']);
        
       // alert("Here!");
    }

    const onChangePicture = e => {
        /// alert(e.target.files[0]);
         if (e.target.files[0]) {
           console.log("picture: ", e.target.files);
           SetPicture(e.target.files[0]);
           const reader = new FileReader();
           reader.addEventListener("load", () => {
            SetMainPhoto(reader.result);
           });
           reader.readAsDataURL(e.target.files[0]);
         }
       };


useEffect(async ()=>{
    var url_string = window.location.href; //
    var url = new URL(url_string);
    var atractType = url.searchParams.get("atractType");
    SetatractType(atractType);

    const atract=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/GetAtractJson",{atractType:atractType});
    console.log(atract);
    SetTitleVal(atract.BasicData['AtractTitle']);
    SetTextura(atract.BasicData['Description']);
    SetMaxNumber(atract.BasicData['MaxNumberPerHour']);
    SetVisitorsArray(atract.CustomerType);
    SetDaysInWeek(atract.OpenHours);
    SetAttractid(atract.BasicData.id);
    SetMainPhoto(atract.BasicData['MainPhoto']);
    SetDbobject(atract);
   

  ////  console.log("הנה השעות")
    ///console.log(atract.OpenHours)
    //CustomerType
    ///SetDaysInWeek()

    ///alert(id);
    

},[])



    useEffect(()=>{
        console.log(VisitorsArray)

    },[VisitorsArray])
    
    function SetVisitorsTypeTEXT_FN(val){
        SetVisitorsTypeTEXT(val);



    }

    function SetVisitorsPricesTEXT_FN(val){
        SetVisitorsPricesTEXT(val);
        


    }
    function SetTime(val){
        var temp=DaysInWeek;

       /// val['index']][val['Type']]=val[val['Type']

        temp[val['index']][val['Type']]=val[val['Type']];
        //SetDaysInWeek()
        console.log(temp)
    }
    function SetValueMaxNumber(val){
        SetMaxNumber(val);
    }
    function SetValue(val){
        console.log(val)
    }
    function btnCliked(){
        if(VisitorsTypeTEXT === '' || VisitorsPricesTEXT === '')
        {
            alert("אחד משדות החובה לא קימות");
        }
        else if(VisitorsPricesTEXT.match(/^[0-9]+$/) == null){
            alert("שדה המחיר חייב להיות מספר!");


        }
        else{
            var exist=VisitorsArray.find((x) => x.VisitorType === VisitorsTypeTEXT);
            if(exist){

                alert("כבר קיים שדה מסוג זה. ראשית יש למחוק אותו .")


            }
            else{
                SetVisitorsArray(VisitorsArray => [...VisitorsArray, {"Type_Name":VisitorsTypeTEXT,"Price":VisitorsPricesTEXT}]);
            }
            
            
            //SetVisitorsArray({...VisitorsArray,});


        }
      //  alert(VisitorsTypeTEXT+'  -  ' + VisitorsPricesTEXT);
       // alert("im here");
    }

    

    function RemoveCol(name){
        ///var temp=VisitorsArray;

       /// temp.splice(index, 1);
       /// updateList();
        SetVisitorsArray(VisitorsArray.filter(item => item.Type_Name !== name));

        //console.log(index);
    }

    async function SendToApi(){
        Setbtnloading(true);
        if(TitleVal === '' || Textura==='' || MaxNumber<=0)
        {
            alert("אחד מהשדות לא מולאו כנדרש!");

        }

        else{
            //console.log(TitleVal);
           // console.log(Textura);
           // console.log(MaxNumber);
            //console.log(DaysInWeek);
            //console.log(VisitorsArray);
      

            var TotalObject={"id":Attractid,"AtractTitle":TitleVal,"Description":Textura,"MaxNumber":MaxNumber,"DaysInWeek":DaysInWeek,"VisitorsArray":VisitorsArray,AtrType:atractType}
           console.log("אובייקט לשליחה");
            console.log(DaysInWeek);
         

            if(Picture !== null){
                const uploadimage= await SendFile("https://bengurion.wdev.co.il/api/admin/management/FileUpload",Picture);
                ///console.log(uploadimage)
                if(uploadimage !==false){

                    
                    const UploadimageURL=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/UpdateLink",{url:uploadimage,id:Attractid});
                    console.log(UploadimageURL);

                }


            }
          
            const addAtract=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/EditAtract",TotalObject);
            
           // const addAtract=await Sendreq("https://bengurion.wdev.co.il/api/admin/management/AddAtract",TotalObject);




            if(addAtract===false){
             ///SetDataobject(Dataobject => [...Dataobject, closingdates]);
             alert("שגיאה.");
         
            }
            /// console.log(closingdates)


            console.log(addAtract);
            alert("הפרטים עודכנו בהצלחה!");
            Setbtnloading(false);

        }

        



    }




//console.log(array["Sunday"]);



    return (
        <>
   
     {console.log(DaysInWeek)}
      <Container fluid>

<Row>
                    <Col xs={2} id="sidebar-wrapper">      
                      <Sidebar />
                    </Col>
                    <Col  xs={10} id="page-content-wrapper">
                        
                          <div className="mainatractContect">
                            <Container>
                              <h2 >אטרקציה ראשית במתחם הצריף</h2>
                           
                              <h1 className="h1mainatract">פירוט אטרקציה ראשית</h1>
                              <FloatingLabel
                                    controlId="floatingInput"
                                    label="כותרת פעילות"
                                    className="mb-3 atractinput1"
                                >
                                    <Form.Control value={TitleVal} onChange={(e)=>{SetTitleVal(e.target.value)}} type="text" placeholder="name@example.com" />
                                </FloatingLabel>

                                <FloatingLabel  label="תוכן הפעילות">
                                    <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    className="atractinput1"
                                    style={{ height: '200px' }}
                                    value={Textura}
                                    onChange={(e)=>{SetTextura(e.target.value)}}
                                    />
                                    </FloatingLabel>
                                    
                                    <h1 className="h1mainatract">תמונה ראשית</h1>
                                   
                                    <Col md={12}>
                                    <img onClick={DelImage} className="DelImage" src="delete.png" />
                                        <img className="ShowMainImage" src={MainPhoto} />
                                    
                                    </Col>
                                    <h3 style={{MarginTop:"10px;"}}>העלאת תמונה חדשה</h3>
                                    
                                    <Form.Group controlId="formFileMultiple"  className="mb-3">
                                    <Form.Control onChange={onChangePicture} id="files" className="hidden" type="file" multiple />
                                    <label className="UploadImage" for="files">בחר תמונה ראשית לאטרקציה</label>
                              
                                    
                                    </Form.Group>

                                    <h1 className="h1mainatract">שעות פעילות המתחם</h1>

                                    {DaysInWeek.map((item,index)=>
                                             <OpenHours key={index} num={index} day={item} SetTime={SetTime} />
                                    
                                    )}
{/* 
                                    <OpenHours num={0} day={DaysInWeek[0]} SetTime={SetTime} />
                                    <OpenHours num={1} day={DaysInWeek[1]} SetTime={SetTime} />
                                    <OpenHours num={2} day={DaysInWeek[2]} SetTime={SetTime} />
                                    <OpenHours num={3} day={DaysInWeek[3]} SetTime={SetTime} />
                                    <OpenHours num={4} day={DaysInWeek[4]} SetTime={SetTime} />
                                    <OpenHours num={5} day={DaysInWeek[5]} SetTime={SetTime} />
                                    <OpenHours num={6} day={DaysInWeek[6]} SetTime={SetTime} /> */}


                                    <h1 className="h1mainatract">הגדרת מלאי כרטיסים</h1>

                                    <Forms clas={"atractinput1"}  title={"מספר המבקרים המקסימלי בשעה"}  value={MaxNumber} SetValue={SetValueMaxNumber} />

                                    <h1 className="h1mainatract">הגדרת מבקרים</h1>

                                    <Row>
                                    <Col md={4}><Forms title={"סוגי מבקרים"} clas={""} style={{ width: '280px' }} value={VisitorsTypeTEXT} SetValue={SetVisitorsTypeTEXT_FN} /> </Col>     
                                    <Col md={4}><Forms title={"מחיר לפי מבקר"} clas={""}  style={{ width: '280px' }} value={VisitorsPricesTEXT} SetValue={SetVisitorsPricesTEXT_FN} /></Col>     
                                    <Col md={2}><img onClick={btnCliked} className="addTypeBTN" src="../pluse.png"></img></Col>

                                    </Row>

                                    {VisitorsArray.map((item,index)=>
                                     <VisitorsTypes key={index} index={index} typename={item.Type_Name} RemoveCol={RemoveCol} price={item.Price} />
                                    
                                    
                                    )} 

                          
                                       
                                       
                              

                                      

                           
                              </Container>
                          </div>
                          <Button onClick={SendToApi} disabled={btnloading} className="SaveAtractbtn d-flex justify-content-center" variant="success">{btnloading ? "טוען.." : "שמור"}</Button>{' '}
                    </Col> 
                    
                </Row>
               

</Container>
        </>


     
    );

}